import React from 'react'
import { SelectOptionItemType } from '@revolut/ui-kit'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { ImportCandidatesDataInterface } from '@src/interfaces/importCandidates'
import { selectorKeys } from '../api'
import { EmployeeEmails } from '@src/interfaces/selectors'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { ImportCell } from '@src/constants/columns/bulkDataImport'
import { IdAndName } from '@src/interfaces'
import { mapRequisitionId } from '@src/pages/Forms/ImportData/Candidates/config'

export type ImportCandidatesCellOptions = {
  employeeOptions: SelectOptionItemType<EmployeeEmails>[]
  specialisationOptions: SelectOptionItemType<IdAndName<string, string>>[]
  seniorityOptions: SelectOptionItemType<IdAndName<string, string>>[]
  originOptions: SelectOptionItemType<IdAndName<string, string>>[]
  jobPostingOptions: SelectOptionItemType<IdAndName<string, string>>[]
  requisitionOptions: SelectOptionItemType<IdAndName<string, string>>[]
}

export const importCandidatesFullNameColumn: ColumnInterface<
  ImportInterface<ImportCandidatesDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'full_name',
  dataPoint: 'full_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
  insert: ({ data }) => <ImportCell field="full_name" data={data} />,
}

export const importCandidatesRequisitionColumn = (
  options: ImportCandidatesCellOptions,
): ColumnInterface<ImportInterface<ImportCandidatesDataInterface>> => ({
  type: CellTypes.insert,
  idPoint: 'requisition_id',
  dataPoint: 'requisition_id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Requisition',
  insert: ({ data }) => (
    <ImportCell
      field="requisition_id"
      data={data}
      options={options.requisitionOptions}
      type="option"
      renderDisplayValue={value => {
        if (value) {
          const id = mapRequisitionId(value)
          const option = (options.requisitionOptions ?? []).find(
            opt => opt.value.id === id,
          )
          return option?.value.name ?? '-'
        }
        return '-'
      }}
    />
  ),
})

export const importCandidatesSpecialisationColumn = (
  options: ImportCandidatesCellOptions,
): ColumnInterface<ImportInterface<ImportCandidatesDataInterface>> => ({
  type: CellTypes.insert,
  idPoint: 'specialisation',
  dataPoint: 'specialisation',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Specialisation',
  insert: ({ data }) => (
    <ImportCell
      field="specialisation"
      data={data}
      options={options.specialisationOptions}
    />
  ),
})

export const importCandidatesSeniorityColumn = (
  options: ImportCandidatesCellOptions,
): ColumnInterface<ImportInterface<ImportCandidatesDataInterface>> => ({
  type: CellTypes.insert,
  idPoint: 'expected_seniority',
  dataPoint: 'expected_seniority',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Seniority',
  insert: ({ data }) => (
    <ImportCell
      field="expected_seniority"
      data={data}
      options={options.seniorityOptions}
    />
  ),
})

export const importCandidatesOriginColumn = (
  options: ImportCandidatesCellOptions,
): ColumnInterface<ImportInterface<ImportCandidatesDataInterface>> => ({
  type: CellTypes.insert,
  idPoint: 'origin',
  dataPoint: 'origin',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Origin',
  insert: ({ data }) => (
    <ImportCell field="origin" data={data} options={options.originOptions} />
  ),
})

export const importCandidatesEmailColumn: ColumnInterface<
  ImportInterface<ImportCandidatesDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'email',
  dataPoint: 'email',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Email',
  insert: ({ data }) => <ImportCell field="email" data={data} />,
}

export const importCandidatesCountryColumn: ColumnInterface<
  ImportInterface<ImportCandidatesDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'country',
  dataPoint: 'country',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Country',
  insert: ({ data }) => <ImportCell field="country" data={data} />,
}

export const importCandidatesCurrentCompanyColumn: ColumnInterface<
  ImportInterface<ImportCandidatesDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'current_company',
  dataPoint: 'current_company',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Current company',
  insert: ({ data }) => <ImportCell field="current_company" data={data} />,
}

export const importCandidatesRecruiterColumn = (
  options: ImportCandidatesCellOptions,
): ColumnInterface<ImportInterface<ImportCandidatesDataInterface>> => ({
  type: CellTypes.insert,
  idPoint: 'recruiter',
  dataPoint: 'recruiter',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Recruiter',
  insert: ({ data }) => (
    <ImportCell field="recruiter" data={data} options={options.employeeOptions} />
  ),
})

export const importCandidatesHiringManagerColumn = (
  options: ImportCandidatesCellOptions,
): ColumnInterface<ImportInterface<ImportCandidatesDataInterface>> => ({
  type: CellTypes.insert,
  idPoint: 'hiring_manager',
  dataPoint: 'hiring_manager',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Hiring manager',
  insert: ({ data }) => (
    <ImportCell field="hiring_manager" data={data} options={options.employeeOptions} />
  ),
})

export const importCandidatesJobPostingColumn = (
  options: ImportCandidatesCellOptions,
): ColumnInterface<ImportInterface<ImportCandidatesDataInterface>> => ({
  type: CellTypes.insert,
  idPoint: 'job_posting',
  dataPoint: 'job_posting',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Job posting',
  insert: ({ data }) => (
    <ImportCell field="job_posting" data={data} options={options.jobPostingOptions} />
  ),
})

export const importCandidatesPhoneColumn: ColumnInterface<
  ImportInterface<ImportCandidatesDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'phone',
  dataPoint: 'phone',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'phone',
  insert: ({ data }) => <ImportCell field="phone" data={data} />,
}

export const importCandidatesLinkedInUrlColumn: ColumnInterface<
  ImportInterface<ImportCandidatesDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'linkedin_url',
  dataPoint: 'linkedin_url',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'LinkedIn',
  insert: ({ data }) => <ImportCell field="linkedin_url" data={data} />,
}

export const importCandidatesPreferredWorkLocationColumn: ColumnInterface<
  ImportInterface<ImportCandidatesDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'preferred_work_locations',
  dataPoint: 'preferred_work_locations',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Preferred work locations',
  insert: ({ data }) => <ImportCell field="preferred_work_locations" data={data} />,
}

export const importCandidatesSourcerColumn = (
  options: ImportCandidatesCellOptions,
): ColumnInterface<ImportInterface<ImportCandidatesDataInterface>> => ({
  type: CellTypes.insert,
  idPoint: 'sourcer',
  dataPoint: 'sourcer',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Sourcer',
  insert: ({ data }) => (
    <ImportCell field="sourcer" data={data} options={options.employeeOptions} />
  ),
})

export const importCandidatesNotesColumn: ColumnInterface<
  ImportInterface<ImportCandidatesDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'notes',
  dataPoint: 'notes',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'notes',
  insert: ({ data }) => <ImportCell field="notes" data={data} />,
}
